import moment from 'moment';
import {startWith} from 'rxjs';
import config from '@root/config';
import LocaleService from '@root/services/locale';
// import LoggerService from '@root/services/logger';
import {map as rxmap, switchMap} from 'rxjs/operators';
import {get, map, isNull, hasIn, isUndefined, find, toString} from 'lodash';
import TrackingService, {
  LOGIN,
  REGISTER,
  OPEN_APP,
  PAGE_VIEW,
  COUPON_EVENT,
  ADD_TO_CART,
  PRODUCT_SEARCH,
  CHECKOUT_FAILED,
  REMOVE_FROM_CART,
  CHECKOUT_SUCCESS,
  PROCEED_TO_CHECKOUT,
  PROFILE_DATA_LOADED,
  SET_DELIVERY_TIMESLOT,
  SELECT_PAYMENT_METHOD,
} from '@root/services/tracking';

class Clevertap {
  constructor() {
    if (!this.instance) this.instance = this;
    return this.instance;
  }

  init() {
    let datastream = switchMap(event =>
      TrackingService.dataStream.pipe(rxmap(pool => ({pool, event}))),
    );

    TrackingService.eventStream
      .pipe(startWith({type: OPEN_APP}), datastream)
      .subscribe(eventpool => {
        switch (eventpool.event.type) {
          case OPEN_APP:
            this.firstLoad(eventpool);
            break;
          case LOGIN:
            this.login(eventpool);
          case REGISTER:
            this.userRegister(eventpool);
            break;
          case PAGE_VIEW:
            this.pageView(eventpool);
            break;
          case PRODUCT_SEARCH:
            this.productSearch(eventpool);
            break;
          case ADD_TO_CART:
            this.addToCart(eventpool);
            break;
          case COUPON_EVENT:
            this.couponEvent(eventpool);
            break;
          case REMOVE_FROM_CART:
            this.removeFromCart(eventpool);
            break;
          case PROCEED_TO_CHECKOUT:
            this.proceedToCheckout(eventpool);
            break;
          case SET_DELIVERY_TIMESLOT:
            this.setDeliveryTimeSlot(eventpool);
            break;
          case SELECT_PAYMENT_METHOD:
            this.paymentMethodSelect(eventpool);
            break;
          // case VIEW_PROMO_PRODUCTS:
          //   this.viewPromoProducts(eventpool);
          //   break;
          case CHECKOUT_FAILED:
            this.checkoutFailed(eventpool);
            break;
          case CHECKOUT_SUCCESS:
            this.checkoutSuccess(eventpool);
            break;
          case PROFILE_DATA_LOADED:
            this.profileDataLoaded(eventpool);
            break;
        }
      });
  }

  _strOnly(value) {
    if (isUndefined(value) || isNull(value) || !value) return '';
    else return toString(value);
  }

  _floatOnly(value) {
    if (isUndefined(value) || isNull(value) || !value) return 0.0;
    else return parseFloat(value);
  }

  _userProps(user) {
    let id = get(user, ['customer_id']);
    let firstname = get(user, ['firstname'], '');
    let lastname = get(user, ['lastname'], '');
    let email = get(user, ['email'], '');
    let group = get(user, ['group']);
    let phone = get(user, ['mobilenumber'], '');
    if (group) tosend['Group'] = group;

    return {
      Name: `${firstname} ${lastname}`,
      Identity: id,
      Email: email,
      Phone: phone,

      // adding clevertap permissions
      ...config.clevertap_permissions,
    };
  }

  firstLoad(event) {
    try {
      let device = get(event, ['pool', 'device'], 'na');
      let userdata = get(event, ['pool', 'values', 'user'], {});
      let user = this._userProps(userdata);

      window.clevertap.onUserLogin.push({Site: user});
      window.clevertap.event.push('Site Load', {
        source: device,
        date: moment().format('DD, MMM YY'),
      });
    } catch (error) {
      // LoggerService.logError('CleverTap Site Load event issue', error);
    }
  }

  pageView(event) {
    try {
      let url = get(event, ['event', 'data', 'url']);
      let device = get(event, ['pool', 'device'], 'na');
      window.clevertap.event.push('Page View', {
        url,
        source: device,
        date: moment().format('DD, MMM YY'),
      });
    } catch (error) {
      // LoggerService.logError('CleverTap Page View event issue', error);
    }
  }

  productSearch(event) {
    try {
      let device = get(event, ['pool', 'device'], 'na');
      let history = get(event, ['event', 'data', 'searchHistory'], []);
      let searchKeyward = get(event, ['event', 'data', 'searchKeyward'], '');

      window.clevertap.onUserLogin.push({
        Site: {
          searchHistory: history,
          search_keywards: searchKeyward,
        },
      });
      window.clevertap.event.push('Search', {
        source: device,
        search_keyward: searchKeyward,
        language: LocaleService.current,
        date: moment().format('DD, MMM YY'),
      });
    } catch (error) {
      // LoggerService.logError('CleverTap Search Event error', error);
    }
  }

  removeFromCart(event) {
    try {
      let device = get(event, ['pool', 'device'], 'na');
      let product = get(event, ['event', 'data', 'product']);
      let cartItem = get(event, ['event', 'data', 'cartItem']);
      let from = get(product, ['from']);
      let label = get(product, ['label']);

      let name = hasIn(cartItem, ['name'])
        ? cartItem.name
        : get(product, ['name'], '');
      let price = hasIn(cartItem, ['price'])
        ? `AED ${cartItem.price}`
        : get(product, ['price'], '');

      let tosend = {
        Currency: 'AED',
        source: device,
        Name: this._strOnly(name),
        Price: this._strOnly(price),
        Qty: this._floatOnly(cartItem.qty),
        Date: moment().format('DD, MMM YY'),
        Id: this._strOnly(cartItem.product_id),
      };
      if (label) tosend['Category'] = label;
      if (from) tosend['FromScreen'] = from;

      window.clevertap.event.push('Removed From Cart', tosend);
    } catch (error) {
      // LoggerService.logError('CleverTap Remove from cart Event error', error);
    }
  }

  addToCart(event) {
    try {
      let device = get(event, ['pool', 'device'], 'na');
      let product = get(event, ['event', 'data', 'product'], {});
      let cartItem = get(event, ['event', 'data', 'cartitem'], {});
      let from = get(product, ['from']);
      let label = get(product, ['label']);

      if (isNull(product) || isNull(cartItem)) return;

      let tosend = {
        Currency: 'AED',
        source: device,
        Id: this._strOnly(product.id),
        Language: LocaleService.current,
        Name: this._strOnly(product.name),
        Qty: this._floatOnly(cartItem.qty),
        Date: moment().format('DD, MMM YY'),
        Price: this._strOnly(product.price_raw),
      };
      if (label) tosend['Category'] = label;
      if (from) tosend['FromScreen'] = from;

      window.clevertap.event.push('Added To Cart', tosend);
    } catch (error) {
      // LoggerService.logError('CleverTap Add to cart Event error', error);
    }
  }

  couponEvent(event) {
    try {
      let device = get(event, ['pool', 'device'], 'na');
      let coupon_code = get(event, ['event', 'data', 'code'], '');
      let screen = get(event, ['event', 'data', 'screen'], '');
      let action = get(event, ['event', 'data', 'action'], '');
      let tosend = {
        action: action,
        screen: screen,
        source: device,
        date: moment().format('DD, MMM YY'),
      };
      if (coupon_code) tosend['coupon_code'] = coupon_code;

      window.clevertap.event.push('couponEvent', tosend);
    } catch (error) {
      // LoggerService.logError('CleverTap Coupon event failed', error);
    }
  }

  login(event) {
    try {
      let device = get(event, ['pool', 'device'], 'na');
      let user = get(event, ['pool', 'user'], {});
      let id = get(user, ['customer_id']);
      let firstname = get(user, ['firstname'], '');
      let lastname = get(user, ['lastname'], '');
      let email = get(user, ['email'], '');
      let group = get(user, ['group']);
      let phone = get(user, ['mobilenumber'], '');

      let tosend = {
        Name: `${firstname} ${lastname}`,
        Identity: id,
        Email: email,
        Phone: phone,

        // adding clevertap permissions
        ...config.clevertap_permissions,
      };
      if (group) tosend['Group'] = group;

      window.clevertap.onUserLogin.push({Site: tosend});
      window.clevertap.event.push('user_login', {
        ...tosend,
        source: device,
        date: moment().format('DD, MMM YY'),
      });
    } catch (error) {
      // LoggerService.logError('CleverTap Login event issue', error);
    }
  }

  userRegister(event) {
    try {
      let device = get(event, ['pool', 'device'], 'na');
      let user = get(event, ['event', 'data'], {});
      let id = get(user, ['customer_id']);
      let firstname = get(user, ['firstname'], '');
      let lastname = get(user, ['lastname'], '');
      let email = get(user, ['email'], '');
      let group = get(user, ['group']);
      let phone = get(user, ['mobilenumber'], '');

      let tosend = {
        Name: `${firstname} ${lastname}`,
        Identity: id,
        Email: email,
        Phone: phone,

        // adding clevertap permissions
        ...config.clevertap_permissions,
      };
      if (group) tosend['Group'] = group;

      window.clevertap.event.push('User Registered', {
        ...tosend,
        source: device,
        date: moment().format('DD, MMM YY'),
      });

      console.log('--------------------clevertap: ', tosend);
      window.clevertap.onUserLogin.push({Site: tosend});
    } catch (error) {
      console.log('--------------------clevertap: ', error);
    }
  }

  proceedToCheckout(event) {
    try {
      let device = get(event, ['pool', 'device'], 'na');
      let items = get(event, ['event', 'data', 'items'], []);
      let products = map(items, item => ({
        Currency: 'AED',
        Id: this._strOnly(item.id),
        Name: this._strOnly(item.name),
        Qty: this._floatOnly(item.qty),
        Date: moment().format('DD, MMM YY'),
        Price: this._floatOnly(item.price),
      }));
      let tosend = {
        Items: products,
        source: device,
        date: moment().format('DD, MMM YY'),
        Item_QTY: this._floatOnly(get(event, ['event', 'data', 'items_count'])),
        Grand_Total: this._floatOnly(
          get(event, ['event', 'data', 'grand_total']),
        ),
      };

      window.clevertap.event.push('Proceed To Checkout', tosend);
    } catch (error) {
      // LoggerService.logError(
      //   'CleverTap Proceed to checkout event issue',
      //   error,
      // );
    }
  }

  setDeliveryTimeSlot(event) {
    try {
      let device = get(event, ['pool', 'device'], 'na');
      let tosend = {
        source: device,
        date: moment().format('DD, MMM YY'),
        Slot_Date: this._strOnly(get(event, ['event', 'data', 'date'])),
        Slot_Time: this._strOnly(get(event, ['event', 'data', 'time', 'slot'])),
      };
      window.clevertap.event.push('Set Delivery Timeslot', tosend);
    } catch (error) {
      // LoggerService.logError('CleverTap Select TimeSlot event issue', error);
    }
  }

  paymentMethodSelect(event) {
    try {
      let device = get(event, ['pool', 'device'], 'na');
      let tosend = {
        source: device,
        date: moment().format('DD, MMM YY'),
        Payment_Method: get(event, ['event', 'data']),
      };
      window.clevertap.event.push('Select Payment Method', tosend);
    } catch (error) {
      // LoggerService.logError('CleverTap Select Payment event issue', error);
    }
  }

  checkoutSuccess(event) {
    try {
      let device = get(event, ['pool', 'device'], 'na');
      let currency = get(event, ['event', 'data', 'base_currency_code']);
      let shipping = get(event, ['event', 'data', 'base_shipping_incl_tax']);
      let tax = get(event, ['event', 'data', 'base_tax_amount']);
      let orderid = get(event, ['event', 'data', 'increment_id']);
      let total = get(event, ['event', 'data', 'base_grand_total']);
      let items = get(event, ['event', 'data', 'items'], []);
      let slot_date = get(event, [
        'event',
        'data',
        'extension_attributes',
        'delivery_date_order',
      ]);
      let slot_time = get(event, [
        'event',
        'data',
        'extension_attributes',
        'delivery_time_order',
      ]);
      let mappedItems = map(items, item => ({
        item_id: toString(get(item, ['item_id'])),
        item_name: get(item, ['name']),
        item_variant: get(item, ['sku']),
      }));
      let reward_points_used = get(event, [
        'event',
        'data',
        'extension_attributes',
        'rewards_spend',
      ]);

      let payment_method_data = get(
        event,
        ['event', 'data', 'extension_attributes', 'payment_additional_info'],
        [],
      );
      let method_found_value = find(payment_method_data, {key: 'method_title'});
      let payment_method = get(method_found_value, ['value']);

      let shipping_region = get(event, [
        'event',
        'data',
        'extension_attributes',
        'shipping_assignments',
        0,
        'shipping',
        'address',
        'region',
      ]);

      let tosend = {
        Currency: currency,
        Items: mappedItems,
        source: device,
        Region: this._strOnly(shipping_region),
        Points_Used: reward_points_used,
        Payment_Method: this._strOnly(payment_method),
        Slot_Date: this._strOnly(slot_date),
        Slot_Time: this._strOnly(slot_time),
        Shipping: parseFloat(shipping),
        Tax: parseFloat(tax),
        Transaction_id: orderid,
        Value: parseFloat(total),
        date: moment().format('DD, MMM YY'),
      };
      window.clevertap.event.push('Charged', tosend);
    } catch (error) {
      // LoggerService.logError('CleverTap Checkout success event error', error);
    }
  }

  checkoutFailed(event) {
    try {
      let device = get(event, ['pool', 'device'], 'na');
      let orderId = get(event, ['event', 'data', 'orderId']);
      window.clevertap.event.push('Order Failed', {
        OrderId: orderId,
        source: device,
        date: moment().format('DD, MMM YY'),
      });
    } catch (error) {
      // LoggerService.logError('CleverTap Checkout fail event error', error);
    }
  }
}
const ClevertapTracker = new Clevertap();
export default ClevertapTracker;
